<template>
    <!-- <div class="sidebar-left ">
        <div class="sidebar"> -->
    <div class="sidebar-content" :class="{ 'show': mqShallShowLeftSidebarFeedback }">
        <vue-perfect-scrollbar ref="refStudentLogPS" :settings="perfectScrollbarSettings"
            class="interviewfeedback-profile-scroller" style=" overflow-x:hidden;">
            <validation-observer :ref="`updateFeedbackForm-${studentId}`" #default="{ invalid }">

                <form @submit.stop.prevent="handleSubmit(data)" class="text-white">
                    <validation-provider #default="{ errors }" name="status" rules="required">
                        <b-form-group :state="errors.length > 0 ? false : null">

                            <label> Select Status<label class="text-danger">*</label></label>
                            <v-select v-model="status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :state="errors.length > 0 ? false : null" :options="statusOptions" name="status"
                                :reduce="val => val.value" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>

                    </validation-provider>
                    <b-form-group>
                        <label for="rating-lg-no-border" >Communication Skills<label class="text-danger"> *</label></label>
                        <b-form-rating id="rating-lg-no-border" class="align-items-start py-0" v-model="comSkill" no-border variant="warning" size="lg"
                             show-value precision="2" />
                    </b-form-group>
                    <b-form-group>
                        <label for="rating-lg-no-border" >Technical Skills<label class="text-danger"> *</label></label>
                        <b-form-rating id="rating-lg-no-border" class="align-items-start py-0" v-model="comSkill" no-border variant="warning" size="lg"
                             show-value precision="2" />
                    </b-form-group>
                    <b-form-group>
                        <label for="rating-lg-no-border" >Leadership Skills<label class="text-danger"> *</label></label>
                        <b-form-rating id="rating-lg-no-border" class="align-items-start py-0" v-model="comSkill" no-border variant="warning" size="lg"
                             show-value precision="2" />
                    </b-form-group>
                    <b-form-group>
                        <!-- <validation-provider #default="{ errors }" name="remarks"> -->

                        <label class="mb-50"> Add Remarks</label>
                        <b-form-textarea v-model="remarks" rows="4" placeholder="Add your remarks here..."
                            name="remarks" />
                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->

                        <!-- </validation-provider> -->
                    </b-form-group>

                    <b-button class="mt-3" block variant="primary" type="submit" @click.prevent="handleSubmit(data)">
                        Submit
                    </b-button>
                </form>
            </validation-observer>

        </vue-perfect-scrollbar>
    </div>
    <!-- </div>
    </div> -->
</template>
<script>
import {
    BAvatar, BInputGroup, BInputGroupPrepend, BFormInput, BMedia, BMediaAside, BMediaBody, BFormGroup, BFormTextarea, BButton, BFormRating
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { avatarText } from "@core/utils/filter";
import firebase from "firebase/app";
import "firebase/firestore";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from "vue-select";

export default {
    components: {

        // BSV
        BAvatar,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BMedia,
        BMediaAside,
        BMediaBody,
        BFormGroup, BFormTextarea,
        ValidationObserver,
        ValidationProvider,
        BButton,
         BFormRating,
        // 3rd party
        VuePerfectScrollbar,
        vSelect

    },
    props: {
        mqShallShowLeftSidebarFeedback: {
            type: Boolean,
            required: true,
        },
        studentId: {
            type: String,
            required: true,
        }
    },
    created() {
        if (this.studentId) {
            console.log('in created')
            this.fetchStudentData()
        }
    },
    watch: {
        studentId() {
            console.log(this.studentId)
            this.fetchStudentData()
        }
    },
    data() {
        return {
            required,
            data: {},
            studentData: {},
            comSkill:0,
            jobId: null,
            status: null,
            remarks: null,
            statusOptions: [
                { label: "Did not Pick", value: "Did not Pick" },
                { label: "Reschedule", value: "Reschedule" },
                { label: "Rejected", value: "Rejected" },
                { label: "Shortlisted", value: "Shortlisted" },
                { label: "Hired", value: "Hired" },
            ],
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            }
        }
    },
    methods: {
        avatarText,
        fetchStudentData() {
            firebase
                .firestore()
                .collection("StudentID")
                .doc(this.studentId)
                .onSnapshot((doc) => {
                    console.log(doc.data());
                    this.studentData = doc.data();
                    this.data = doc.data();

                });
        },
        updateFeedback(tr) {
        },
        handleSubmit(data) {
        },

    },

}
</script>
<style lang="scss">
.interviewfeedback-profile-scroller {
    .form-control{
        background-color: transparent;
        color: #fff;
    }
    .v-select.vs--single .vs__selected {
        color: #fff
    }

    label {
        color: #fff;
    }

    textarea.form-control {
        background-color: transparent;
        color: #fff;
    }
}
</style>