<template>
    <div class="pt-2 px-3 d-flex flex-column justify-content-between bg-gray text-white" style="min-height: 100vh;">
        <b-row class="match-height flex-fill">
            <b-col md="8" sm="12">
                <b-card no-body class="bg-light-gray text-white">
                    <!-- :src="data.profilephoto" -->
                    <b-card-body class="d-flex flex-column justify-content-center align-items-center">
                        <div v-if="connections.length > 0 && connections[0].videoEnabled" class="card-user-video">
                            <VideoElement :stream="connections[0].connectionType === 'self'
                                ? connections[0].localStream
                                : connections[0].remoteStream
                            " :muted="connections[0].muted" :connectionType="connections[0].connectionType"
                                :videoEnabled="connections[0].videoEnabled" :userData="studentData"
                                class="grid-view-img px-0" />
                        </div>
                        <div class="flex-fill align-middle my-4" v-else>
                            <b-avatar class=" badge-minimal avatar-border-2 text-white " size="250"
                                :text="avatarText(studentData.studentName)" variant="transparent"
                                :src="studentData.studentPhoto" style="border-radius: 82px !important;" />
                        </div>
                        <div class=" d-flex flex-column justify-content-between  video-labels">
                            <div class="align-self-end">
                                <b-button variant="transparent" class="btn-icon">
                                    <u-icon v-if="connections.length > 0 && connections[0].audioEnabled"
                                        icon="fi-rr-microphone"></u-icon>
                                    <b-img v-else :src="require('@/assets/images/svg/mic-off.svg')"
                                        style="height:14px; color:#fff;"></b-img>
                                </b-button>
                            </div>
                            <div class="p-2 text-center" style="background-color: rgba(4, 60, 87, 0.5)"
                                v-if="connections.length <= 0">
                                <h4 class="text-white"> Waiting for {{ studentData.studentName }} to join the interview
                                </h4>
                            </div>
                            <h5 class="font-weight-bold"> {{ studentData.studentName }}</h5>
                        </div>
                        <b-card no-body class="bg-light-gray text-white overlay-video-card">
                            <!-- :src="data.profilephoto" -->
                            <b-card-body class="d-flex flex-column justify-content-center align-items-center ">
                                <div v-if="videoEnabled" class="card-sself-video">
                                    <VideoElement :stream="stream1" :muted="muted" connectionType="self"
                                        :videoEnabled="videoEnabled" :userData="currentUserData" />
                                </div>
                                <div class="flex-fill align-middle mx-4 my-1" v-else>

                                    <b-avatar class=" badge-minimal avatar-border-2 text-white flex-fill" size="90"
                                        :text="avatarText(currentUserData.FullName)" variant="transparent"
                                        :src="currentUserData.profilephoto" style="border-radius: 28px !important;" />
                                </div>
                                <div class="video-labels-you">
                                    <h5 class="font-weight-bold"> You</h5>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card class="bg-light-gray text-white">
                    <!-- <portal to="content-renderer-sidebar-left"> -->
                    <student-profile-sidebar :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
                        v-if="mqShallShowLeftSidebar" :studentId="studentId">
                    </student-profile-sidebar>
                    <!-- </portal> -->
                    <interview-feedback :mq-shall-show-left-sidebar-feedback.sync="mqShallShowLeftSidebarFeedback"
                        v-else :studentId="studentId"></interview-feedback>
                </b-card>
            </b-col>
        </b-row>
        <div class="w-100 mb-2 " style="height: 1px; border-top:1px solid #efefef"></div>
        <b-row class="match-height mb-1">
            <b-col cols="3">
                <div class="d-flex justify-content-start align-items-center">
                    <b-img :src="require('@/assets/images/logo/logo.png')" height="28" style="max-width: fit-content;"
                        v-b-popover.hover.top="
                        'This call is End to end encrypted'">
                    </b-img>
                    <div class="mx-1" style="align-self: center;border-left: 1px solid #dadce0;height: 3rem;"></div>
                    <!-- <u-icon icon="fi-sr-lock"  class="text-success cursor-pointer mr-50" v-b-popover.hover.left="
                    'End to end encryption'"></u-icon> -->
                    <div>
                        <b-card-title class="mb-75 font-small-4">
                            Audio Call with {{ studentData.studentName }}
                        </b-card-title>
                        <b-card-sub-title class="font-small-3">
                            Duration : 00:00:00
                        </b-card-sub-title>
                    </div>
                </div>
            </b-col>
            <b-col cols="6">
                <div class=" d-flex  justify-content-center">
                    <b-button variant="secondary" class="btn-icon mr-1" v-b-tooltip.hover.top="'Turn off microphone'"
                        @click="onAudioToggle()">
                        <u-icon v-if="audioEnabled" icon="fi-rr-microphone"></u-icon>
                        <b-img v-else :src="require('@/assets/images/svg/mic-off.svg')"
                            style="height:14px; color:#fff;"></b-img>
                    </b-button>
                    <b-button variant="secondary" class="btn-icon mr-1" v-b-tooltip.hover.top="'Turn off camera'"
                        @click="onVideoToggle()">
                        <u-icon icon="fi-rr-video-camera"></u-icon>
                    </b-button>
                    <b-button variant="secondary" class="btn-icon mr-1" v-b-tooltip.hover.top="'switch to full screen'">
                        <u-icon icon="fi-rr-expand"></u-icon>
                    </b-button>
                    <b-button variant="danger" class="" v-b-tooltip.hover.top="'Leave Call'" @click="handleEndEvent()">
                        <u-icon icon="fi-rr-phone-slash"></u-icon>
                    </b-button>
                </div>
            </b-col>
            <b-col cols="3" class="d-flex align-items-end">
                <div class=" d-flex  justify-content-center">
                    <b-button variant="secondary" class="btn-icon mr-1" v-b-tooltip.hover.top="'View student resume'"
                        @click="setSidebar('resume')">
                        <u-icon icon="fi-rr-comment-user"></u-icon>
                    </b-button>

                    <b-button variant="secondary" class="btn-icon" v-b-tooltip.hover.top="'Interview Feedback'" v-if="$acl.get[0] == 'recruiter'"
                        @click="setSidebar('feedback')">
                        <u-icon icon="fi-rr-test"></u-icon>
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <!-- <b-card class="w-100 " no-body>
            <b-card-body class="d-flex justify-content-between">
                <div class="d-flex w-25 align-items-center">
                    <u-icon icon="fi-rr-volume" class="mr-1" size="22"></u-icon>
                    <div class="w-100 align-middle">
                    <vue-slider v-model="sliderVal" :lazy="true" direction="ltr" /></div>
                </div>
                <div class=" d-flex">
                    <div class="d-flex flex-column  align-items-center mr-2">
                        <b-button variant="secondary" class="btn-icon">
                            <u-icon icon="fi-rr-microphone"></u-icon>
                        </b-button>
                        <span class="font-small-2">Mic</span>
                    </div>
                    <div class="d-flex flex-column  align-items-center">
                        <b-button variant="secondary" class="btn-icon">
                            <u-icon icon="fi-rr-video-camera"></u-icon>
                        </b-button>
                        <span class="font-small-2">Cam</span>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <b-button variant="danger">
                        <u-icon icon="fi-rr-phone-slash" class=" btn-icon"></u-icon>
                    </b-button>
                    <span class="font-small-2">End</span>
                </div>

            </b-card-body>
        </b-card> -->
    </div>
</template>
<script>
import {
    BRow, BCol, BCard, BAvatar, BButton, BCardBody, BImg, BCardHeader, BCardTitle, VBPopover, BCardSubTitle, VBTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import VueSlider from 'vue-slider-component'
import Meeting from "@/views/apps/videoCall/lib/meeting/meeting";
import StudentProfileSidebar from "./components/StudentProfileSidebar.vue";
import VideoElement from "./components/VideoElement.vue";
import InterviewFeedback from "./components/InterviewFeedback.vue";
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BButton,
        BCardBody,
        BImg,
        BCardHeader,
        BCardTitle,
        BCardSubTitle,
        VueSlider,
        StudentProfileSidebar,
        VideoElement,
        InterviewFeedback
    },
    directives: {
        "b-popover": VBPopover,
        'b-tooltip': VBTooltip,

    },
    created() {
        this.studentId = this.$route.params.studentId
        // console.log(this.studentId)
        this.meetingDetails = this.$route.params.meetingDetails
        try {
            if (this.meetingDetails != null && this.meetingDetails.meetingId != null) {
                this.setData;

                this.meetingDetails.on("connection", (connection) => {
                    console.log("here connecting");
                    var totalConnections = this.meetingDetails.connections;
                    totalConnections.forEach((connect) => {
                        connect.userId != "" ? this.connections.push(connect) : null;
                    });
                });
                this.meetingDetails.on("user-left", (connection) => {
                    this.handleUserLeft(connection);
                });
                this.meetingDetails.on("ended", this.handleEndEvent);
                this.meetingDetails.on(
                    "connection-setting-changed",
                    this.handleConnectionSettingChange()
                );
            }
        } catch (err) {

        }
    },
    watch: {
        stream1() {
            this.setConnections();
        }
    },
    data() {
        return {
            sliderVal: 75,
            meetingDetails: null,
            mqShallShowLeftSidebar: true,
            mqShallShowLeftSidebarFeedback: false,
            studentId: null,
            stream1: MediaStream,
            stream2: MediaStream,
            currentUserData: this.$store.state.auth.AppActiveUser,
            studentData: null,
            connections: [],
            isHost: true,
            isConnectionFailed: false,
            muted: false,
            videoEnabled: true,
            audioEnabled: true,
            meetingId: null
        }
    },
    computed: {
        setData() {
            this.stream1 = this.meetingDetails.stream
            console.log('this is stream')
            console.log(this.stream1)
            this.audioEnabled = this.meetingDetails.audioEnabled;
            this.meetingId = this.meetingDetails.meetingId;
            this.videoEnabled = this.meetingDetails.videoEnabled;
            this.connections = this.meetingDetails.connections;
            this.studentData = {
                studentName: this.$store.state.interview.connectedStudent.s_name,
                studentId: this.$store.state.interview.connectedStudent.s_uid,
                studentPhoto: this.$store.state.interview.connectedStudent.displayPic
            }
        }
    },
    methods: {
        setSidebar(input) {
            if (input == 'resume') {
                this.mqShallShowLeftSidebarFeedback = false
                this.mqShallShowLeftSidebar = true
            } else if (input == 'feedback') {
                this.mqShallShowLeftSidebarFeedback = true
                this.mqShallShowLeftSidebar = false
            }
        },
        avatarText,
        setConnections() {

        },
        handleUserLeft(connection) {
            const index = this.connections.findIndex(
                (conn) => conn.userId === connection.userId
            );
            // console.log(index)
            this.connections.splice(index, 1);
        },
        async handleEndEvent() {
            await this.meetingDetails.end();
            // this.$emit("closeCall");
            localStorage.removeItem("meeting");
            localStorage.removeItem("meetingId");
            //emit event to cloase dialog
            // this.$router.go(-1);
            var role = this.$acl.get[0]
            if (role == 'recruiter') {
                this.$router.push({
                    name: 'candidate-profile',
                    params: { studentId: this.studentId, jobId: this.$route.params.jobId },
                })
            } else if (role == 'admin') {
                this.$router.push({
                    name: 'student-profile',
                    params: { studentId: this.studentId },
                })

            }
        },
        handleConnectionSettingChange() {
            console.log("here setting changed");
            var payoad = "here setting changed";
        },
        onAudioToggle() {
            this.audioEnabled = !!this.meetingDetails.toggleAudio();
        },
        onVideoToggle() {
            this.videoEnabled = !!this.meetingDetails.toggleVideo();
        },
        contextMenuFunct(event) {
            // console.log(event)
            event.preventDefault();
        },
    }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';

.interviewstudent-profile-scroller {
    height: calc(100vh - (3.429rem + 4.45rem + 4rem));

}

.interviewfeedback-profile-scroller {
    height: calc(100vh - (3.429rem + 4.45rem + 4rem));

}

.bg-gray {
    background-color: #202124;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: #fff
    }
}

.bg-light-gray {
    background-color: #3C4043;
}

.card {
    .video-labels {
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        top: 1.5rem;
        max-width: calc(100% - 3.0rem);
        min-width: calc(100% - 3.0rem);
    }
}

// #3C4043
.card {
    .video-labels-you {
        position: absolute;
        bottom: 0.6rem;
        right: 1.5rem;
    }
}

.card-user-video {
    margin: -1.5rem;

    video {
        border-radius: 1.143rem;
        max-height: calc(100vh - (3.429rem + 4.45rem + 4rem));
    }
}

.overlay-video-card {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.6), -2px -2px 2px rgba(4, 60, 87, 0.2);
    transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s;

    .card-sself-video {
        margin: -1.5rem;

        video {
            max-width: 180px;
            border-radius: 1.143rem;
            width: max-content;
        }
    }
}

.btn-transparent {
    background-color: rgba(4, 60, 87, 0.4);
    color: #fff
}
</style>