<template>
  <div v-if="toshowVideo">
    <video
      id="myVideoElem"
      autoplay
      class="video media-video-player card-video"
      @change.self="checkvideo($event)"
      v-if="type === 'self'"
      playsInline
      ref="videoelem"
      @contextmenu="contextMenuFunct"
    ></video>
    <video
      id="myVideoElem"
      autoplay
      class="video2 media-video-player card-video"
      v-else
      playsInline
      ref="videoelem"
      @contextmenu="contextMenuFunct"
      :srcObject="stream"
    ></video>
  </div>
  <div class="items-center justify-center" v-else>
    <!-- <div class="profile-img-container p-8"> -->
    <div class="p-8">
      <div>
        <vs-avatar
          circle
          class="cursor-pointer border border-2 border-solid d-theme-border-grey-light"
          size="150"
          ><img :src="userData.profilephoto" />
        </vs-avatar>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "video-element",
    props: {
      stream: {
        type: MediaStream,
      },
      muted: {
        type: Boolean,
      },
      connectionType: {
        type: String,
      },
      videoEnabled: {
        type: Boolean,
      },
      userData: {
        type: Object,
        required: true,
      },
    },
    watch: {
      videoEnabled: function async() {
        this.toshowVideo = this.videoEnabled;
        if (this.toshowVideo) {
          this.$nextTick(() => {
            this.setdata();
          });
        }
      },
      stream: function () {
        //   console.log(this.$refs);
        this.$refs.videoelem.srcObject = this.stream;
        this.$refs.videoelem.muted = this.muted;
        this.$refs.videoelem.volume = this.muted ? 0.1 : 1.0;
      },
      checkfunction: function () {
        var tracks = this.stream.getTracks();
        // console.log(tracks)
      },
      deep: true,
    },

    data() {
      return {
        isMounted: false,
        type: "self",
        toshowVideo: true,
      };
    },
    mounted() {
      this.isMounted = true;
      console.log(`checking video enabled ${this.videoEnabled}`);
      if (this.videoEnabled) {
        this.toshowVideo = this.videoEnabled;
        this.$refs.videoelem.srcObject = this.stream;
        this.$refs.videoelem.muted = this.muted;
        this.$refs.videoelem.volume = this.muted ? 0.1 : 1.0;
      }
    },
    methods: {
      checkvideo(event) {
        var videotracks = this.stream.getVideoTracks();
        console.log(event);
        this.setdata();
      },
      contextMenuFunct(event) {
        // console.log(event)
        event.preventDefault();
      },
      setdata() {
        console.log(`checking video enabled ${this.videoEnabled}`);
        this.type = this.connectionType;
        console.log(this.$refs);
        if (typeof this.$refs.videoelem !== "undefined") {
          console.log(this.$refs.videoelem.srcObject);
        }
        if (this.isMounted && typeof this.$refs.videoelem !== "undefined") {
          // this.$nextTick(() => {
          console.log("hence mounted");
          this.$refs.videoelem.srcObject = this.stream;
          this.$refs.videoelem.muted = this.muted;
          this.$refs.videoelem.volume = this.muted ? 0.1 : 1.0;
          // });
        }
      },
    },
    created() {
      this.setdata();
      var videotracks = this.stream.getVideoTracks();

      this.$nextTick(() => {
        console.log("next tick running");
        if (videotracks[0].enabled === false) {
          this.$refs.videoelem.srcObject = null;
        } else {
          if (this.videoEnabled) {
            this.$refs.videoelem.srcObject = this.stream;
          }
        }
      });
    },
  };
</script>
<style lang="scss">
  .video2 {
    // height: 670px;
    width: 100%;
    object-fit: contain;
    background: url('~@/assets/images/svg/chat.svg') no-repeat;
    background-size: contain;
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg);
  }
  .video {
    // height: 670px;
    width: 100%;
    object-fit: contain;
    background: url('~@/assets/images/svg/chat.svg') no-repeat;
    background-size: contain;
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg);
  }
</style>
