<template>
	<div class=" mt-3 mb-1">
		<h5 class="mb-1" style=" font-weight:600">
			Skills
		</h5>
		<b-badge 
			v-for="skill in skillsList" :key="skill"
			pill
			variant="light-primary"
			class="text-capitalize  mr-2 mb-1"
		>
		<span class="text-white">	{{ skill }}</span>
		</b-badge>
	</div>
</template>
<script>
import { BBadge } from "bootstrap-vue";

export default {
	components: {
		BBadge,
	},
	props: {
		skillsList: {
			type: Array,
		},
	},
};
</script>
