<template>
    <!-- <div class="sidebar-left ">
        <div class="sidebar"> -->
    <div class="sidebar-content" :class="{ 'show': mqShallShowLeftSidebar }">
        <vue-perfect-scrollbar ref="refStudentLogPS" :settings="perfectScrollbarSettings"
            class="interviewstudent-profile-scroller" style=" overflow-x:hidden;">
            <b-media no-body>
                <b-media-aside class="mr-1">
                    <b-avatar class=" badge-minimal avatar-border-2 text-primary" size="56" :src="data.profilephoto"
                        :text="avatarText(data.FullName)" variant="transparent"
                        style="border-radius: 16px !important;" />
                </b-media-aside>
                <b-media-body>
                    <h5 class=" font-weight-bold">
                        {{ data.FullName }}
                    </h5>
                    <h6 class="mt-50 mb-25" style=" font-weight:600">
                        {{ data.CollegeName }}
                    </h6>
                    <span class=" font-small-3" style="font-weight:600">{{ data.courseName }}</span>
                </b-media-body>
            </b-media>
            <table class="mt-1  w-100 variety-table">
                <tr>
                    <td class="pb-25">
                        <div class="d-flex align-items-start ">
                            <!-- <i class='bx bx-male-sign' v-if="data.gender == 'Male'"></i> -->
                            <u-icon v-if="data.gender == 'Female'" icon="fi-rr-venus" class="mr-1" size="16" />
                            <u-icon v-else icon="fi-rr-mars" class="mr-1" size="16" />

                            <span>{{ data.gender }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="pb-25">
                        <div class="d-flex align-items-start ">
                            <u-icon icon="fi-rr-smartphone" class="mr-1" size="16" />
                            <span>{{ data["ContactNo."] }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="pb-25">
                        <div class="d-flex align-items-start ">
                            <u-icon icon="fi-rr-cake-birthday" class="mr-1" size="16" />
                            <span>{{ data.DOB }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="pb-25 ">
                        <div class="d-flex align-items-start ">
                            <u-icon icon="fi-rr-envelope" class="mr-1" size="16" />
                            <span class="">{{ data.email }}</span>
                        </div>
                    </td>
                </tr>
            </table>
            <hr/>
            <all-details-component label="Education" :dataArray="educationdata"></all-details-component>
            <all-details-component label="Experience" v-if="experiencedata.length > 0" :dataArray="experiencedata">
            </all-details-component>
            <skills v-if="typeof (skills) != 'undefined' && skills.length > 0" :skills-list="skills">
            </skills>
            <all-details-component v-if="accompData.length > 0" label="Accomplishments" :dataArray="accompData">
            </all-details-component>
        </vue-perfect-scrollbar>
    </div>
    <!-- </div>
    </div> -->
</template>
<script>
import {
    BAvatar, BInputGroup, BInputGroupPrepend, BFormInput, BMedia, BMediaAside, BMediaBody
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { avatarText } from "@core/utils/filter";
import firebase from "firebase/app";
import "firebase/firestore";
import AllDetailsComponent from "./allDetailsComponent.vue";
import Skills from "./skills.vue";

export default {
    components: {

        // BSV
        BAvatar,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BMedia,
        BMediaAside,
        BMediaBody,

        // 3rd party
        VuePerfectScrollbar,
        AllDetailsComponent,
        Skills
    },
    props: {
        mqShallShowLeftSidebar: {
            type: Boolean,
            required: true,
        },
        studentId: {
            type: String,
            required: true,
        }
    },
    created() {
        if (this.studentId) {
            console.log('in created')
            this.fetchStudentData()
        }
    },
    watch: {
        studentId() {
            console.log(this.studentId)
            this.fetchStudentData()
        }
    },
    data() {
        return {
            data: {},
            studentData: {},
            experiencedata: [],
            educationdata: [],
            accompData: [],
            skills: [],
            jobId: null,
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
            }
        }
    },
    methods: {
        avatarText,
        fetchStudentData() {
            firebase
                .firestore()
                .collection("StudentID")
                .doc(this.studentId)
                .onSnapshot((doc) => {
                    console.log(doc.data());
                    this.studentData = doc.data();
                    this.data = doc.data();
                    // console.log(this.studentData.AppliedIn)
                    this.getExperience;
                    this.getEducation;
                    this.getAccomp;
                });
        },
    },
    computed: {
        getAccomp() {
            this.accompData = []
            const db = firebase
                .firestore()
                .collection("StudentID")
                .doc(this.studentId)
                .collection("OtherDetails")
                .doc(this.studentId);
            db.get()
                .then((doc) => {
                    if (doc.exists) {
                        this.skills = doc.data().skills ?? [];
                    }
                })
                .then(() => {
                    db.collection("Certification")
                        .get()
                        .then((snapshot) => {
                            snapshot.docs.forEach((doc) => {
                                var obj = {
                                    color: "primary",
                                    icon: "bx-trophy",
                                    title: doc.data().name,
                                    desc: doc.data().desc,
                                    time: doc.data().date,
                                };
                                this.accompData.push(obj);
                            });
                        });
                });
        },
        getEducation() {
            var obj = {};
            var obj1 = {};
            var obj2 = {};
            this.educationdata = []
            const db = firebase
                .firestore()
                .collection("StudentID")
                .doc(this.studentId)
                .collection("EducationDetails")
                .doc(this.studentId);
            db.get()
                .then((doc) => {
                    if (doc.exists) {
                        // console.log(doc.data())
                        obj = {
                            color: "primary",
                            icon: "bx-book-reader",
                            title: `${doc.data().courseName} - ${doc.data().branch
                                }`,
                            desc: doc.data().CollegeName,
                            time:
                                typeof doc.data().AdmDate != "string"
                                    ? `${doc
                                        .data()
                                        .AdmDate.toDate()
                                        .getFullYear()} - ${doc
                                            .data()
                                            .grad_date.toDate()
                                            .getFullYear()}`
                                    : `${doc.data().AdmDate} - ${doc.data().grad_date
                                    }`,
                            score: doc.data().current_Percentage,
                        };
                        // this.educationdata.push(obj);

                        if (doc.data().twelve != null) {
                            obj1 = {
                                color: "primary",
                                icon: "bx-book-reader",
                                title: "Class 12th",
                                desc: doc.data().twelve.school,
                                time: doc.data().twelve.end_date,
                                score: doc.data().twelve.score,
                            };
                            // this.educationdata.push(obj1);
                        }
                        if (doc.data().tenth != null) {
                            obj2 = {
                                color: "primary",
                                icon: "bx-book-reader",
                                title: "Class 10th",
                                desc: doc.data().tenth.school,
                                time: doc.data().tenth.end_date,
                                score: doc.data().tenth.score,
                            };
                            // this.educationdata.push(obj2);
                        }
                    }
                })
                .then(() => {
                    db.collection("PreviousEducationDetails")
                        .get()
                        .then((snapshot) => {
                            if (snapshot.size > 0) {
                                this.educationdata.push(obj);

                                snapshot.docs.forEach((value) => {
                                    var obj3 = {
                                        color: "primary",
                                        icon: "bx-book-reader",
                                        title: `${value.data().courseName} - ${value.data().branch
                                            }`,
                                        desc: value.data().CollegeName,
                                        time: `${value
                                            .data()
                                            .AdmDate.toDate()
                                            .getFullYear()} - ${value
                                                .data()
                                                .grad_date.toDate()
                                                .getFullYear()}`,
                                        score: value.data().current_Percentage,
                                    };
                                    this.educationdata.push(obj3);
                                });
                                this.educationdata.push(obj1);
                                this.educationdata.push(obj2);
                            } else {
                                this.educationdata.push(obj);
                                this.educationdata.push(obj1);
                                this.educationdata.push(obj2);
                            }
                        })
                        .then(() => {
                            // console.log("here");
                            this.education;
                        });
                });
        },
        getExperience() {
            this.experiencedata = []
            const db = firebase
                .firestore()
                .collection("StudentID")
                .doc(this.studentId)
                .collection("ExperienceDetails");
            db.get().then((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    var obj = {
                        color: "primary",
                        icon: "bx-briefcase-alt-2",
                        title: `${doc.data().JobTitle} - ${doc.data().CompanyName
                            }`,
                        desc: doc.data().details,
                        time: `${doc.data().start_date} - ${doc.data().end_date
                            }`,
                        type: doc.data().positiontype,
                    };
                    this.experiencedata.push(obj);
                });
            });
        },
    },
}
</script>