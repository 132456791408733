<template>
  <div class="mt-2 mb-2" style="padding-right: 2rem;">
    <h5 class="mb-1" style=" font-weight:600;">
      {{ label }}
    </h5>
  <details-timeline>
    <details-timeline-item v-for="(item,index) in dataArray" :key="index"
      :title="item.title"
      :subtitle="item.desc"
      icon="fi-rr-check"
      :time="item.score"
      :variant="item.color"
      :extraQuote="item.time" 
    />
  </details-timeline>
    </div>

</template>

<script>
import DetailsTimeline from '@core/components/details-timeline/DetailsTimeline.vue'
import DetailsTimelineItem from '@core/components/details-timeline/DetailsTimelineItem.vue'

export default {
  components: {
    DetailsTimeline,
    DetailsTimelineItem,
  },
  props:{
    dataArray:{
      type:Array
    },
    label:{
      type:String
    }
  }
}
</script>
